@import '../../../scss/base.scss';

$ball_size:20px;

.MobileVerticalNumberTrendWrapper{
  background-color: white;
  margin: 16px 0px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 12px;

  .columnPart{
   &>div{
      height: 40px;
      border-bottom: 1px solid #C9C9C9 !important;
   }
  }

  .ball{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 16px;
   height: 16px;
   border-radius: 50%;
   background-color: #FFC400;
   font-weight: 500;
  }

  .dd{
   color: white;
   background-color: #D9A34D;

  }
  .xs{
   color: white;
   background-color: #797979;
  }

  .rowCellWrapper{
   width: 100%;
   height: 100%;
   display: flex;
   &>div{
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   &>div:not(:last-child){
      border-right: 1px solid #C9C9C9;
   }
  }

  .headerCellWrapper{
   width: 100%;
   height: 100%;
   &>div{
      width: 100%;
   }
   &>div:last-child{
      border-top: 1px solid #C9C9C9;
      display: flex;
      &>div{
         flex: 1;
      }
      &>div:not(:last-child){
         border-right: 1px solid #C9C9C9;
      }
   }
  }


  .tableWrapper{
   width: 400px;
   overflow-x: scroll;
   margin-bottom: 10px;
   border: solid black 1px;
   table td:first-child {
      position: sticky;
      left: 0;
      background-color: #ddd;
    }

   thead{
     background-color: white !important;
     }
     table, th, td {
        border-bottom: 1px solid #C9C9C9 !important;
        border-collapse: collapse;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
}

 .ant-select-selector, .ant-picker{
   @include themify($themes) {
      border: 1px solid themed('theme_first_color');  
    }
}

.buttonWrapper{
 color: black;
 border-radius: 8px;
 @include themify($themes){
   border: 1px solid themed('theme_forth_color');  
 }
 padding: 5px 10px;
 margin: 5px 15px 5px 0px;
 &:hover{
   cursor: pointer;
 }
 &.selected{
   color: white;
   @include themify($themes){
     border-color: themed('theme_second_color');  
     background-color: themed('theme_second_color');  
   }
 }
}
.PKS{
   color: white;
   height: $ball_size;
   width: $ball_size;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 6px;
   margin: 3px;
   &.yellow{
     background-color: #D9A34D;
  }
  &.grey{
     background-color: #797979;
  }
      &._1{
         background-color: $PKS_1;
      }
      &._2{
            background-color: $PKS_2;
         }
      &._3{
         background-color: $PKS_3;
      }
      &._4{
         background-color: $PKS_4;
      }
      &._5{
         background-color: $PKS_5;
      }
      &._6{
         background-color: $PKS_6;
      }
      &._7{
         background-color: $PKS_7;
      }
      &._8{
         background-color: $PKS_8;
      }
      &._9{
         background-color: $PKS_9;
      }
      &._10{
         background-color: $PKS_10;
      }
 }

 .SSC{
   color: black;
   height: $ball_size;
   width: $ball_size;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   margin: 3px;
   background-color:$SSC_Yellow ;
 }

 .KLSF{
 height: $ball_size;
   width: $ball_size;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   margin: 3px;
   &.red{
      background-color:$KLSF_Red ;
      color: white;
   }
   &.yellow{
      background-color:$KLSF_Yellow;
      color: black;
   }
 }

 .SYXW{
   color: black;
   height: $ball_size;
   width: $ball_size;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   margin: 3px;
   background-color:$SYXW_Yellow ;
 }

}