@import './scss/base.scss';

.KG186App {
  height: 100%;
  @include themify($themes) {
    background-color: themed('theme_bg_color');  
  }
}


