@import '../../../scss/base.scss';

.MobileChangLongLiangMianSideMenuWrapper{
    z-index: 2;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px !important;
    right: 0px !important;
    .menuHeader{
        padding: 0px 16px;
        height: 56px;
        @include themify($themes) {
            background-color: themed('theme_second_color');  
          }
          color: white;
          font-size: 18px;
    }
    .lotteryContent{
        font-size: 14px;
        flex: 1;
        background-color: white;
        overflow-y: scroll;
        padding: 30px 16px 50px 16px;
        .textColor{
          @include themify($themes) {
            color: themed('theme_second_color');  
          }
        }
        .liangMianWrapper{
          &>div:not(:first-child){
            margin: 10px;
          }
          &>div{
            border-bottom: 1px solid #B2B2B2;
            padding-bottom: 10px;
          }
        }
        .mobileChangLongWrapper{
          border-top: 1px solid #B2B2B2;
          .changLongItem{
            margin: 10px;
            border-bottom: 1px solid #B2B2B2;
            padding-bottom: 10px;
            display: flex; 
            align-items: center;
            &>div:first-child{
              width:100px;
              text-align: center;
            }
          }
        }

    }
   
}