@import '../../../scss/base.scss';

$ball_size:32px;

.planWrapper{

   @include themify($themes) {
      background-color: themed('theme_bg_color');  
    }
    width: 100%;
    padding: 40px 0px;

   &>div{
      max-width: 1192px;
      margin-left: auto;
      margin-right: auto;
    }

   .introduceCell{
      &>div{
         padding: 10px 0px;
      }
      &>div:first-child{
         font-weight: bold;
         border-bottom: 2px solid #B2B2B2;
      }

   }

   .resultSummary{
      &>div:not(:last-child){
         border-right: 1px solid #C9C9C9;
      }
      .resultSummaryCell{
         flex: 1;
         &>div:first-child{
            @include themify($themes) {
               background-color: themed('theme_third_color');  
             }
         }
         &>div{
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
         }
         &>div:not(:last-child){
            border-bottom: 1px solid #C9C9C9;
         }
      }
   }

   .latestHeader{
      &>div:not(:last-child){
         border-right: 1px solid #C9C9C9;
      }
      &>div{
         height: 100%;
      }
      @include themify($themes) {
         background-color: themed('theme_third_color');  
       }
       &.noBgColor{
         background-color: white;
         &>div{
            color: black !important;
         }
       }
   }
   .killNumberCalculate{
      height: 100%;
      &>div:not(:last-child){
         border-right: 1px solid #C9C9C9;
      }
      &>div{
         height: 100%;
      }
      .killColorfalse{
         color: #3F80D9;
      }
      .killColortrue{
         @include themify($themes){
            color: themed('theme_second_color');  
         }
      }
      .killColorBgtrue{
         @include themify($themes){
            background-color: themed('theme_second_color');  
         }
      }
   }

   .ant-select-selector, .ant-picker{
      @include themify($themes) {
         border: 2px solid themed('theme_first_color');  
       }
   }

  .PKS{
    color: white;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: bold;
    
       &._1{
          background-color: $PKS_1;
       }
       &._2{
             background-color: $PKS_2;
          }
       &._3{
          background-color: $PKS_3;
       }
       &._4{
          background-color: $PKS_4;
       }
       &._5{
          background-color: $PKS_5;
       }
       &._6{
          background-color: $PKS_6;
       }
       &._7{
          background-color: $PKS_7;
       }
       &._8{
          background-color: $PKS_8;
       }
       &._9{
          background-color: $PKS_9;
       }
       &._10{
          background-color: $PKS_10;
       }
  }

  .SSC, .KS{
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color:$SSC_Yellow ;
    font-weight: bold;
  }

  .KLSF{
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
    
    &.red{
       background-color:$KLSF_Red ;
       color: white;
    }
    &.yellow{
       background-color:$KLSF_Yellow;
       color: black;
    }
  }

  .SYXW{
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;  
    background-color:$SYXW_Yellow ;
    font-weight: bold;
  }
  
  .dots-1 {
    margin-left: 5px;
    margin-bottom: 5px;
    align-self: flex-end;
    width: 30px;
    height: 8px;
    @include themify($themes){
    background: radial-gradient(circle closest-side,white 90%,themed('theme_second_color')) 0/calc(100%/3) 100% space;
    }
    clip-path: inset(0 100% 0 0);
    animation: d1 1.5s steps(4) infinite;
  }
  @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}

   .latestKillPlan{
      padding: 20px;
      height: 240px;
      width: 100%;
      border-radius: 10px;
      @include themify($themes){
         background-color: themed('theme_second_color');  
      }
      .homeGameImage{
         height: 118px;
         width: 118px;
      } 
      .childMenu{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid white;
      width: 100px;
      height: 40px;
      border-radius: 20px;
      }
   }

   .dateAndCount{
      padding: 0px 30px;
      margin-top: 25px;
      margin-bottom: 35px;
      width: 100%;
      height: 60px;
      @include themify($themes){
         background-color: themed('theme_second_color');  
      }
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
   }
   .killPlanListWrapper{
      width: 100%;
      padding: 30px;
      background-color: white;
      border: 1px solid #FFFFFF;
      border-radius: 12px;
      box-shadow: 0px 3px 6px #33333329;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      .buttonWrapper{
         color: black;
         border-radius: 8px;
         @include themify($themes){
         border: 1px solid themed('theme_forth_color');  
         }
         padding: 5px 10px;
         margin: 5px 15px 5px 0px;
         &:hover{
         cursor: pointer;
         }
         &.selected{
         color: white;
         @include themify($themes){
            border-color: themed('theme_second_color');  
            background-color: themed('theme_second_color');  
         }
         }

      }
   }
}