@import '../../../scss/base.scss';

.mobileSideMenuWrapper{
    z-index: 2;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px !important;
    right: 0px !important;
    .menuHeader{
        padding: 0px 16px;
        height: 56px;
        @include themify($themes) {
            background-color: themed('theme_second_color');  
          }
          color: white;
          font-size: 18px;
    }
    .lotteryContent{
        font-size: 14px;
        flex: 1;
        background-color: white;
        overflow-y: scroll;
        padding: 0px 16px 90px 16px;

        .categoryName{
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: bold;
            @include themify($themes) {
                color: themed('theme_second_color');  
              }
        }

        .buttonWrapper{
            color: black;
            border-radius: 6px;
            @include themify($themes){
              border: 1px solid themed('theme_forth_color');  
            }
            padding: 6px 8px;
            margin: 5px 10px 5px 0px;
            &:hover{
              cursor: pointer;
            }
            &.selected{
              color: white;
              @include themify($themes){
                border-color: themed('theme_second_color');  
                background-color: themed('theme_second_color');  
              }
            }
      
          }
    }
    
}