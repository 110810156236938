@import '../../../scss/base.scss';

.mobileHeaderWrapper{
    padding: 0px 16px;
    height: 45px;
    @include themify($themes) {
        background-color: themed('theme_second_color');  
      }
      color: white;
      font-size: 18px;
      &>div{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }

      .colorPanelActive{
        @include themify($themes) {
          background-color: themed('theme_first_color') !important;  
        }
      }

      .colorPopover{
        box-shadow: 0px 2px 10px #00000029;
        z-index: 2;
        width: 110px;
        height: 110px;
        border-radius: 10px;
        background-color: white;
        color: black;
        top: 56px;
        right: 15px;
        padding: 20px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
          .colorBlock{
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            width: 30px;
            height: 30px;
            border-radius: 5px;
              &.red{
                background-color: #E86262;
              }
              &.green{
                background-color: #4A9F5E;
              }
              &.blue{
                background-color: #5D66D3;
              }
              &.orange{
                background-color: #E38533;
              }                         
            }                  
          }
}