@import '../../../scss/base.scss';

.downloadWrapper{
    max-width: 550px;
    width: 100%;
    margin: 0px auto;
    background-color: #F2F8FF;
    height: 100%;
    background-image: url('../../../assets//download//166-background.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;
    object-fit: cover;

    .mask, .mask2, .mask3, .mask4, .mask5 {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      display: block;
  }

    .download_page_top{
      width: 100%;
      height: 100%;
      font-family: adobe-heiti-std, sans-serif;
    }

    .download_page_bottom{
      position: fixed;
      background-color: #fff;
      padding-bottom: 5px;
      height: 550px;
      .installDesc{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a93ac;
      }
      .installStep{
        padding: 10px 0px;
        font-size: 12px;
      }
      .installDescImage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
          margin-bottom: 20px;
        }
      }
    }

    .downloadAppBtn{
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: white;
      font-size: 14px;
      cursor: pointer;
      width: 140px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #00a4ff;

    }

    .password-mask{
      position: fixed;
      z-index: 9999;
      background-color: rgba(0,0,0,0.2);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      display: block;
      .password-input{
        width: 100%;
        height: 50px;
        margin-top: 50%;
        font-size: 30px;
      }
      .password-submit-button{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        font-size: 30px;
        background-color: #fff;
      }
    }

    .author-code-mask{
      position: fixed;
      z-index: 9999;
      background-color: rgba(0,0,0,0.2);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      display: block;
      .author-code-input{
        width: 100%;
        height: 50px;
        margin-top: 50%;
        font-size: 30px;
      }
      .author-code-submit-button{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        font-size: 30px;
        background-color: #fff;
      }
    }
}