@import '../../../scss/base.scss';

#trendTooltipContainer{
    position: absolute;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #B2B2B2;
    opacity: 1;
    transition: all .2s ease;
    pointer-events: none;
  }