@import '../../../scss/base.scss';

.MobileChangLongDrawerWrapper {
  .ant-drawer-body{
    padding: 16px !important;

  }
  .drawerHeader {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
    @include themify($themes) {
      background-color: themed('theme_second_color');
    }
    .drawerHeaderTitle {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .checkArea {
    .checkTitleArea {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .checkTitle {
        font-weight: bold;
        font-size: 16px;
        // font: normal normal bold 18px/26px Noto Sans SC;
        @include themify($themes) {
          color: themed('theme_second_color');
        }
      }
    }

    .checkItemArea {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .checkItem {
        // 作为一个button
        display: inline-block;
        letter-spacing: 0px;
        padding: 6px 8px;
        border-radius: 6px;

        @include themify($themes) {
          border: 1px solid themed('theme_second_color');
        }
        &.checked {
          @include themify($themes) {
            color: white;
            background-color: themed('theme_second_color');
            border: 1px solid themed('theme_second_color');
          }
        }
      }
    }

    // ant 修改主题
    //checkbox
    .ant-checkbox-wrapper {
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          @include themify($themes) {
            background-color: themed('theme_first_color');
            border-color: themed('theme_first_color');
          }
        }
        &::after {
          @include themify($themes) {
            border-color: themed('theme_first_color');
          }
        }
      }
      &:hover {
        .ant-checkbox {
          .ant-checkbox-inner {
            @include themify($themes) {
              border-color: themed('theme_first_color');
            }
          }
        }
        .ant-checkbox-checked {
          &::after {
            @include themify($themes) {
              border-color: themed('theme_first_color');
            }
          }
          .ant-checkbox-inner {
            @include themify($themes) {
              background-color: themed('theme_first_color');
              border-color: themed('theme_first_color') !important;
            }
          }
        }
      }
    }
  }
}

.MobileChangLongWrapper {
  background-color: #f0f0f0;

  .content {
    .loadingArea {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // button 用于筛选
    .button {
      display: inline-block;
      letter-spacing: 0px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 8px;
      color: white;
      &:hover {
        cursor: pointer;
      }
      @include themify($themes) {
        background-color: themed('theme_second_color');
        border: 1px solid themed('theme_second_color');
      }
      &.ghost {
        background-color: white;
        color: black;
        @include themify($themes) {
          border: 1px solid themed('theme_forth_color');
        }
      }
      &.small {
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: small;
      }
    }
    .filterArea {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      background-color: white;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;

      .statusButtonArea {
        display: flex;
        align-items: center;
        gap: 4px;
        .statusButton {
          padding-left: 8px;
          padding-right: 8px;
          padding-top: 4px;
          padding-bottom: 4px;
          border-bottom-width: 2px;
          border-bottom-color: transparent;
          &.active {
            @include themify($themes) {
              color: themed('theme_second_color');
              // background-color: themed('theme_second_color');
              border-bottom-color: themed('theme_second_color');
            }
          }
        }
      }
    }
    .selectArea {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .select {
        width: 65px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    .tableArea {
      box-shadow: 5px 5px 2px -2px #00000029;
      margin: 10px 5px;
      background-color: white;
      padding: 16px;
      border-radius: 12px;
      table {
        margin-bottom: 20px;
        table-layout: fixed;
        width: 100%;
        text-align: left;
        .luzhuChakan {
          margin-top: 10px;
          cursor: pointer;
          @include themify($themes) {
            color: themed('theme_first_color');
          }
        }
        th {
          text-align: left;
          height: 40px;

          &:nth-child(3) {
            text-align: center;
          }
        }
        tr {
          border-bottom: 1px solid #c9c9c9;
          td {
            // height: 40px;
            padding-top: 14px;
            padding-bottom: 14px;

            &:nth-child(3) {
              width: 50%;
              text-align: center;
            }
          }
        }
      }
      .paginationArea {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  // ant 修改主题
  // pagination
  .ant-pagination-next,
  .ant-pagination-prev {
    display: none;
  }
  .ant-pagination-item {
    @include themify($themes) {
      border-color: themed('theme_first_color');
    }
  }
  .ant-pagination-item-active {
    @include themify($themes) {
      & a {
        color: white;
      }
      background-color: themed('theme_first_color');
      border-color: themed('theme_first_color');
    }
  }
}
