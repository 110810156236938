@import '../../../scss/base.scss';

.LuZhuWebWrapper {
  // height: 100%;
  &.boxShadow {
    border: 1px solid #ffffff;
    box-shadow: 0px 3px 6px #33333329;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  .empty {
    padding: 10px;
  }
  background-color: white;
  border-radius: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 36px;
  padding-right: 36px;

  .loadingArea {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .titleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .dateFilterArea {
    display: flex;
    align-items: center;
    gap: 20px;
    .dateQucikChoose {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .filterArea {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    .filterTitle {
      font-weight: bold;
    }
    .filterItemsWithCheckAll {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .filterItems {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .button {
    letter-spacing: 0px;
    display: inline-block;
    &:hover {
      cursor: pointer;
      color: black;
    }
    &.active {
      @include themify($themes) {
        background-color: themed('theme_second_color');
        color: white;
        // border: none;
        border: 1px solid transparent;
      }
    }
    @include themify($themes) {
      border: 1px solid themed('theme_forth_color');
    }
    padding: 5px 10px !important;
    border-radius: 8px;
    &.date {
      padding-top: 0;
      padding-bottom: 0;
    }
    &.text {
      border: none;
      padding: 0;
      &:hover {
        @include themify($themes) {
          color: themed('theme_second_color');
        }
      }
      &.active {
        @include themify($themes) {
          color: themed('theme_second_color');
        }
        background-color: transparent;
      }
    }
    &.small {
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    &.confirm {
      margin-right: 10px;
    }
  }
  .tableArea {
    width: 100%;
    border: 1px solid #c9c9c9;
    margin-bottom: 20px;
    .header {
      @include themify($themes) {
        background-color: themed('theme_third_color');
      }
      border-bottom: 1px solid #c9c9c9;
      padding-left: 20px;
      // padding-right: 20px;
      display: flex;
      align-items: stretch;
      // gap: 10px;

      .headerWithoutNewest {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        border-right: 1px solid #c9c9c9;
      }
      .selectArea {
        display: flex;
        align-items: center;
        .select {
          width: 60px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .rightHeader {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .newest {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        color: white;
        width: 40px;
        min-width: 40px;

        @include themify($themes) {
          background-color: themed('theme_first_color');
        }
      }
    }
    .content {
      overflow-x: scroll;
      display: flex;
      flex-direction: row-reverse;
      .col {
        text-align: center;
        width: 40px;
        min-width: 40px;
        border-right: 1px solid #c9c9c9;
        &:last-child {
          border: 0;
        }
        &.highLight {
          @include themify($themes) {
            background-color: themed('theme_third_color');
          }
        }
        .active {
          @include themify($themes) {
            color: themed('theme_second_color');
          }
        }
      }
    }
  }

  // ant 修改主题
  // ant-picker
  .ant-picker {
    &.ant-picker-focused,
    &:hover,
    &:focus,
    &:active {
      @include themify($themes) {
        border-color: themed('theme_first_color');
      }
    }
  }

  // ant-input-number
  .ant-input-number {
    box-shadow: none !important;
    &.ant-input-number-focused,
    &:hover,
    &:focus,
    &:active {
      @include themify($themes) {
        border-color: themed('theme_first_color');
      }
    }
  }
  // spin
  .ant-spin-dot-item {
    // 修改spin item的颜色
    @include themify($themes) {
      background-color: themed('theme_first_color');
    }
  }
  // select
  .ant-select {
    .ant-select-selector {
      box-shadow: none !important;
    }
    &.ant-select-focused {
      .ant-select-selector {
        @include themify($themes) {
          border-color: themed('theme_first_color') !important;
        }
      }
    }
    &:hover {
      .ant-select-selector {
        @include themify($themes) {
          border-color: themed('theme_first_color') !important;
        }
      }
    }
  }

  //checkbox
  .ant-checkbox-wrapper {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        @include themify($themes) {
          background-color: themed('theme_first_color');
          border-color: themed('theme_first_color');
        }
      }
      &::after {
        @include themify($themes) {
          border-color: themed('theme_first_color');
        }
      }
    }
    &:hover {
      .ant-checkbox {
        .ant-checkbox-inner {
          @include themify($themes) {
            border-color: themed('theme_first_color');
          }
        }
      }
      .ant-checkbox-checked {
        &::after {
          @include themify($themes) {
            border-color: themed('theme_first_color');
          }
        }
        .ant-checkbox-inner {
          @include themify($themes) {
            background-color: themed('theme_first_color');
            border-color: themed('theme_first_color') !important;
          }
        }
      }
    }
  }
}
