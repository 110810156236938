@import '../../../scss/base.scss';

.webFooterLinkWrapper{
        padding: 20px 124px;
        @include themify($themes) {
            background-color: themed('theme_second_color');  
          }
          .logoName{
            cursor: pointer;
            width: 250px;
          }
          .footLinkItem{
            cursor: pointer;
          }
}