@import '../../../scss/base.scss';

.webContentWrapper{
    min-height: calc(100% - 136px);
    overflow-y: auto;    
    @include themify($themes) {
        background-color: themed('theme_bg_color');  
      }
      .animationLayer{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
}