
// For PKS template
$PKS_1 :#DBD40B;
$PKS_2 :#1111F2;
$PKS_3 :#3C342C;
$PKS_4 :#F4680C;
$PKS_5 :#18EDED;
$PKS_6 :#C181EF;
$PKS_7 :#758EB7;
$PKS_8 :#E8296F;
$PKS_9 :#781F1F;
$PKS_10 :#0F9393;

// For FJ31X7 FJ36X7 template
$FJC_Red:#D84125;
$FJC_Yellow:#FFC400;

// For KLC template
$KLC_Yellow:#FFC400;
$KLC__Dark_Yellow:#D9A34D;

// For KLSF template
$KLSF_Yellow:#FFC400;
$KLSF_Red:#D84125;

// For QG template
$QG_Red:#D84125;
$QG_Blue:#4591D7;

// For SSC template
$SSC_Yellow:#FFC400;

// For SYXW template
$SYXW_Yellow:#FFC400;

// For HK6 template
$HK6_Green:#27BF00;
$HK6_Red:#D84125;
$HK6_Blue:#1D79FF;



$themes: (
    'red':(
        theme_first_color:#BE4545, //top header, bottom footer
        theme_second_color:#E86262, // top second header, last second footer
        theme_third_color:#FFDEDE, //table header
        theme_forth_color:#7D282B, //notice message
        theme_bg_color:#EBE4E4, // background color
        demo_bg_color:red,
    ),
    'blue':(     
      theme_first_color:#464C9A,
      theme_second_color:#5D66D3,
      theme_third_color:#DBDFF7,
      theme_forth_color:#282F7D,
      theme_bg_color:#ECECF0,   
      demo_bg_color:green,
    ),
    'green':(   
      theme_first_color:#276F38,
      theme_second_color:#4A9F5E,
      theme_third_color:#D6F6DA,
      theme_forth_color:#255D14,
      theme_bg_color:#EDF0EC,     
      demo_bg_color:green,
    ),
    'orange':(     
      theme_first_color:#A76124,
      theme_second_color:#E38533,
      theme_third_color:#FFE6D0,
      theme_forth_color:#724417,
      theme_bg_color:#F0EEEC,   
      demo_bg_color:green,
    )
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  
  @function themed($key) {
    @return map-get($theme-map, $key);
  }