@import '../../../scss/base.scss';

.helpCenterWrapper {
  max-width: 1192px !important;
  &.Web{
    .helpCenterContent{
      margin: 30px 0px;
    font-size: 16px !important;
    background-color: white;
    padding: 24px 36px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
     box-shadow: 0px 3px 6px #33333329;
     backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    .title{
      font-size: 24px;
      margin-bottom: 16px;
    }
    .subTitle{
      margin-bottom: 16px;
    }
    .section {
        margin-bottom: 30px;       
        div {
          margin-bottom: 15px;
        }
      }
    }
}
&.Mobile{
  width: 100%;
  .helpCenterContent{
    background-color: white;
    margin: 16px 5px;
    padding: 10px;

    font-size: 14px !important;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #33333329;
    .title{
      font-size: 16px;
      margin-bottom: 16px;
    }
    .subTitle{
      margin-bottom: 16px;
    }
    .section {
        margin-bottom: 20px;       
        div {
          margin-bottom: 10px;
        }
      }
    }
}
  color: #3b3b3b;
  .subMenuListRow {
    width: 100%;
    overflow-y: auto;
    .menuItem {
      min-width: max-content;
      padding: 10px;
      border-bottom: 2px solid transparent;
      &:hover {
        cursor: pointer;
        @include themify($themes) {
          color: themed('theme_second_color');
        }
      }
      &.selected {
        @include themify($themes) {
          border-bottom: 2px solid themed('theme_second_color');
          color: themed('theme_second_color');
        }
      }
    }
  }
  .primaryColor {
    @include themify($themes) {
      color: themed('theme_second_color');
    }
  }

  .removeMargin {
    .ant-tabs-nav {
      margin: 0;
    }
  }

  // 表格的背景颜色
  th.bgColor {
    @include themify($themes) {
      background-color: themed('theme_third_color');
    }
  }
  .colBgColor {
    @include themify($themes) {
      background-color: themed('theme_third_color');
    }
  }
}
