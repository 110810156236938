@import '../../../scss/base.scss';

.ruleCenterWrapper{
    &.Web{
        font-size: 16px !important;
        background-color: white;
        padding: 24px 36px;
        border-radius: 12px;
        border: 1px solid #FFFFFF;
        border-radius: 12px;
         box-shadow: 0px 3px 6px #33333329;
         backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        .title{
          font-size: 24px;
        }
        .section {
            margin-bottom: 30px;       
            div {
              margin-bottom: 15px;
            }
          }
    }
    &.Mobile{
        background-color: white;
        width: 100%;
        margin: 16px 0px;
        padding: 10px;
        font-size: 14px !important;
        border-radius: 12px;
        border: 1px solid #FFFFFF;
        border-radius: 12px;
        box-shadow: 0px 3px 6px #33333329;
        .title{
          font-size: 16px;
        }
        .section {
            margin-bottom: 20px;       
            div {
              margin-bottom: 10px;
            }
          }
    }
}