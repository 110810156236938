@import '../../../scss/base.scss';

.missingNumberWrapper{
    background-color: white;
    padding: 24px 36px;
    border-radius: 12px;
    .buttonWrapper{
        color: black;
        border-radius: 8px;
        @include themify($themes){
          border: 1px solid themed('theme_forth_color');  
        }
        padding: 5px 10px;
        margin: 5px 15px 5px 0px;
        &:hover{
          cursor: pointer;
        }
        &.selected{
          color: white;
          @include themify($themes){
            border-color: themed('theme_second_color');  
            background-color: themed('theme_second_color');  
          }
        }
      }
}