@import '../../../scss/base.scss';

.NewsWrapper {
  font-size: 16px;
  width: 100%;
  padding: 25px 5px;
  max-width: 1192px;
  margin-left: auto;
  margin-right: auto;
  .boxShadow {
    border: 1px solid #ffffff;
    box-shadow: 0px 3px 6px #33333329;
  }
  @include themify($themes) {
    background-color: themed('theme_bg_color');
  }
  // height: 100%;

  // 去除前后的按钮
  .ant-pagination-next,
  .ant-pagination-prev {
    display: none;
  }

  .primaryColor {
    @include themify($themes) {
      color: themed('theme_second_color');
    }
  }
}
