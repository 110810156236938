@import '../../../scss/base.scss';

// tailwind base 自带的样式，当中button透明。覆盖不了。
// 所以在组件内自定义了button样式

.ApiCallWrapper {

  padding: 25px 0px;
  width: 100%;
  color: #3b3b3b;

  
  &>div{
    max-width: 1192px;
    margin-left: auto;
    margin-right: auto;
  }

  .boxShadow{
    border: 1px solid #FFFFFF;
    box-shadow: 0px 3px 6px #33333329;
  }
  .subMenuListRow {
    width: 100%;
    .menuItem {
      padding: 10px;
      border-bottom: 2px solid transparent;
      &:hover {
        cursor: pointer;
        @include themify($themes) {
          color: themed('theme_second_color');
        }
      }
      &.selected {
        @include themify($themes) {
          border-bottom: 2px solid themed('theme_second_color');
          color: themed('theme_second_color');
        }
      }
    }
  }
  .button {
    &.ant-btn-primary {
      padding: 5px 10px !important;
      @include themify($themes) {
        background-color: themed('theme_second_color');
      }
    }
  }
}
