@import '../../../scss/base.scss';

.mobileLayoutWrapper{
    overflow: auto;
    font-size: 14px;

    .react-datepicker-wrapper{
        color: black;
        border-radius: 5px;
        @include themify($themes){
            border: 1px solid themed('theme_forth_color');  
          }
        width: auto;
        input{
            font-size: 16px !important;
            padding-top: 4px;
            padding-bottom: 4px;
            vertical-align: middle;
            width: 100px;
            border-radius: 5px;
            text-align: center;
        }
    }
}