@import '../../../scss/base.scss';

.tableWrapper{
    text-align: center;
    table{
        width: 100%;
        height: 0px;
    }

    thead{
      @include themify($themes) {
          background-color: themed('theme_third_color');  
        }
    }

    td,th{
      height: 40px;
      padding: 0px;
    }

    table, th, td {
      border: 1px solid #C9C9C9;
      border-collapse: collapse;
    }

    .tableEmptyWrapper{
      padding: 50px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg{
          height: 150px;
      }
      color: #5D6D7B;
    }   
}