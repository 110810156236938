@import '../../../scss/base.scss';

.lotteryWrapper{
  padding: 25px 0px;
  width: 100%;
  color: #3B3B3B;
  &>div{
    max-width: 1192px;
    margin-left: auto;
    margin-right: auto;
  }

    .subMenuListRow{
      width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      .menuItem{
        cursor: pointer;
        padding: 10px 9px;
        border-bottom: 2px solid transparent;
        &.selected, &:hover{     
          @include themify($themes) {
            border-bottom:2px solid themed('theme_second_color');  
            color:themed('theme_second_color');  
            }
        }

      }
    }
}