@import '../../../scss/base.scss';

.webPlanLotteryList{
    background-color: white;
    padding: 15px 15%;
    font-size: 16px;
    overflow-x: auto;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 3px 6px #33333329;
    backdrop-filter: blur(20px);
   -webkit-backdrop-filter: blur(20px);

    .lotteryItem{
        white-space: nowrap;
        &:hover,&.selected{
            cursor: pointer;
            @include themify($themes) {
                color: themed('theme_second_color');  
              }
        }
    }

    .categoryName{
        width: 100px;
        white-space: nowrap;
        @include themify($themes) {
            color: themed('theme_second_color');  
          }
        font-weight:600;
    }
}