@import '../../../scss/base.scss';

$ball_size:20px;

.MobileChannelHomeWrapper{
    background-color: white;
    width: 100%;
    margin: 16px 0px;
    padding: 10px;
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
     box-shadow: 0px 3px 6px #33333329;

    .tableWrapper{
      thead{
        background-color: white !important;
        }
        table, th, td {
           border-bottom: 1px solid #C9C9C9 !important;
           border-collapse: collapse;
           border-top: none !important;
           border-left: none !important;
           border-right: none !important;
         }
  }

    .ant-select-selector, .ant-picker{
      @include themify($themes) {
         border: 1px solid themed('theme_first_color');  
       }
   }

   .buttonWrapper{
    color: black;
    border-radius: 8px;
    @include themify($themes){
      border: 1px solid themed('theme_forth_color');  
    }
    padding: 5px 10px;
    margin: 5px 15px 5px 0px;
    &:hover{
      cursor: pointer;
    }
    &.selected{
      color: white;
      @include themify($themes){
        border-color: themed('theme_second_color');  
        background-color: themed('theme_second_color');  
      }
    }
  }

  .PKS,.PKS_FT{
    color: white;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 2px;
    &.yellow{
      background-color: #D9A34D;
   }
   &.grey{
      background-color: #797979;
   }
       &._1{
          background-color: $PKS_1;
       }
       &._2{
             background-color: $PKS_2;
          }
       &._3{
          background-color: $PKS_3;
       }
       &._4{
          background-color: $PKS_4;
       }
       &._5{
          background-color: $PKS_5;
       }
       &._6{
          background-color: $PKS_6;
       }
       &._7{
          background-color: $PKS_7;
       }
       &._8{
          background-color: $PKS_8;
       }
       &._9{
          background-color: $PKS_9;
       }
       &._10{
          background-color: $PKS_10;
       }
  }

  .SSC,.SSC_FT{
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px;
    background-color:$SSC_Yellow ;
    &.sum{
      background-color: #ffd100;
   }

   &.underLine::after{
      content: '';
      position: absolute;
      margin-top: 24px;
      width:  $ball_size;
      border: 2px solid #ffd100;
   }
  }

  .KLSF{
  height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px;
    &.red{
       background-color:$KLSF_Red ;
       color: white;
    }
    &.yellow{
       background-color:$KLSF_Yellow;
       color: black;
    }
  }

  .SYXW{
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px;
    background-color:$SYXW_Yellow ;
  }

  .BINGOWrapper{
   width: 240px;
   flex-wrap: wrap;
 }

  .BINGO{
   &.textBox{
      border: none;
      white-space: nowrap;
   }
   border: 3px solid;
   color: black;
   height: $ball_size;
   width: $ball_size;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   margin: 2px 5px;
   white-space: nowrap;

   &.yellow{
      width: 45px;
      height: 30px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 9px 2px;
     background-color: #D9A34D;
  }
  &.grey{
      width: 45px;
      height: 30px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 9px 2px;
     background-color: #797979;
  }


   &.red{
      border-color:$HK6_Red ;
   }
   &.blue{
      border-color:$HK6_Blue;
   }
   &.green{
      border-color:$HK6_Green;
   }
   &.ZHANDLH{
      border: none;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2px 5px;
      background-color:$SSC_Yellow ;
   }
 }

 .BINGO_FTWrapper{
   width: 120px;
   flex-wrap: wrap;
 }

  .BINGO_FT{
   border: 3px solid;
   color: black;
   height: $ball_size;
   width: $ball_size;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   margin: 2px;

   &._1{
      border-color: gray;
   }

   &._4{
      border-color:$HK6_Red ;
   }
   &._3{
      border-color:$HK6_Blue;
   }
   &._2{
      border-color:$HK6_Green;
   }

   &.textBox{
      border: none;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2px;
   }

 }

  .KLCWrapper{
    flex-wrap: wrap;
    width: 260px;
  }

  .KLC{
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px;
    &.darK_yellow{
       background-color:$KLC__Dark_Yellow ;
    }
    &.yellow{
       background-color:$KLC_Yellow;
    }
  }

  .FJ36X7,.FJ31X7{
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px;
    &.red{
       background-color:$FJC_Red ;
       color: white;
    }
    &.yellow{
       background-color:$FJC_Yellow;
       color: black;
    }
  }

  .QG{
    color: white;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px;
    &.red{
       background-color:$QG_Red ;
    }
    &.blue{
       background-color:$QG_Blue;
    }
  }

  .KS{
   width: 27px;
   height: 27px;
    margin: 2px;
  }

  .HK6Wrapper{
    width: 234px;
    flex-wrap: wrap;
  }

  .HK6{
    &.textBox{
       white-space: nowrap;
       border: none;
    }
    border: 3px solid;
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px;
    &.red{
       border-color:$HK6_Red ;
    }
    &.blue{
       border-color:$HK6_Blue;
    }
    &.green{
       border-color:$HK6_Green;
    }
  }
    
}