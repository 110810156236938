@import '../../../scss/base.scss';

.HorizontalNumberTrendWrapper{
  background-color: white;
  padding: 24px 36px;
  border-radius: 12px;

  .buttonWrapper{
      color: black;
      border-radius: 8px;
      @include themify($themes){
        border: 1px solid themed('theme_forth_color');  
      }
      padding: 5px 10px;
      margin: 5px 15px 5px 0px;
      &:hover{
        cursor: pointer;
      }
      &.selected{
        color: white;
        @include themify($themes){
          border-color: themed('theme_second_color');  
          background-color: themed('theme_second_color');  
        }
      }
    }
    .PKS{
      color: white;
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 6px;
         &._1{
            background-color: $PKS_1;
         }
         &._2{
               background-color: $PKS_2;
            }
         &._3{
            background-color: $PKS_3;
         }
         &._4{
            background-color: $PKS_4;
         }
         &._5{
            background-color: $PKS_5;
         }
         &._6{
            background-color: $PKS_6;
         }
         &._7{
            background-color: $PKS_7;
         }
         &._8{
            background-color: $PKS_8;
         }
         &._9{
            background-color: $PKS_9;
         }
         &._10{
            background-color: $PKS_10;
         }
    }
  
    .SSC{
      color: black;
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      background-color:$SSC_Yellow ;
    }
  
    .KLSF{
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         background-color:$KLSF_Red ;
         color: white;
      }
      &.yellow{
         background-color:$KLSF_Yellow;
         color: black;
      }
    }
  
    .SYXW{
      color: black;
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      background-color:$SYXW_Yellow ;
    }
  
    .KLCWrapper{
      flex-wrap: wrap;
      width: 480px;
    }
  
    .KLC{
      color: black;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.darK_yellow{
         background-color:$KLC__Dark_Yellow ;
      }
      &.yellow{
         background-color:$KLC_Yellow;
      }
    }
  
    .FJ36X7,.FJ31X7{
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         background-color:$FJC_Red ;
         color: white;
      }
      &.yellow{
         background-color:$FJC_Yellow;
         color: black;
      }
    }
  
    .QG{
      color: white;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         background-color:$QG_Red ;
      }
      &.blue{
         background-color:$QG_Blue;
      }
    }
  
    .KS{
  
      margin: 6px;
    }
  
    .HK6Wrapper{
      width: 432px;
      flex-wrap: wrap;
    }
  
    .HK6{
      &.textBox{
         border: none;
      }
      border: 3px solid;
      color: black;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         border-color:$HK6_Red ;
      }
      &.blue{
         border-color:$HK6_Blue;
      }
      &.green{
         border-color:$HK6_Green;
      }
    }
}