@import '../../../scss/base.scss';

.mobileLotteryWrapper{
    @include themify($themes) {
      background-color: themed('theme_bg_color');  
      }
      height: 100%;
      width: 100%;
      padding: 0px 5px;

      .mobileLotteryCategoryRow{
        background-color: white;
        white-space: nowrap;
        overflow-x: auto;
        border-radius: 10px;
        &>div{
          padding: 10px 0px;
          border-bottom: 3px solid transparent;
          &.selected{        
            @include themify($themes) {
              color: themed('theme_second_color'); ;
              border-bottom-color: themed('theme_second_color');  
              }
          }
        }
      }
}