@import '../../../scss/base.scss';

$ball_size:36px;

.homeDrawItem{
   &:hover{
      cursor: pointer;
  }

  background-color: white;
  padding: 15px 30px;
  font-size: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 12px;
   box-shadow: 0px 3px 6px #33333329;
   backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

   .dots-1 {
      margin-left: 5px;
      margin-bottom: 5px;
      align-self: flex-end;
      width: 30px;
      height: 8px;
      background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
      clip-path: inset(0 100% 0 0);
      animation: d1 1.5s steps(4) infinite;
    }
    @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}

    .homeGameImage{
        height: 118px;
        width: 118px;
    }
    .installmentWrapper{
        @include themify($themes) {
            color: themed('theme_second_color');  
          }
    }

    .PKS,.PKS_FT{
      position: relative;
      color: white;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 6px;
      &.sum{
         background-color: #ffd100;
      }

      &.underLine::after{
         content: '';
         position: absolute;
         margin-top: 44px;
         width: $ball_size;
         border: 2px solid #5D66D3;
      }
      &.underLineRed::after{
         content: '';
         position: absolute;
         margin-top: 44px;
         width: $ball_size;
         border: 2px solid #E86262;
      }
      &.underLineGreen::after{
         content: '';
         position: absolute;
         margin-top: 44px;
         width: $ball_size;
         border: 2px solid #4A9F5E;
      }

      &._1{
         background-color: $PKS_1;
      }
      &._2{
            background-color: $PKS_2;
         }
      &._3{
         background-color: $PKS_3;
      }
      &._4{
         background-color: $PKS_4;
      }
      &._5{
         background-color: $PKS_5;
      }
      &._6{
         background-color: $PKS_6;
      }
      &._7{
         background-color: $PKS_7;
      }
      &._8{
         background-color: $PKS_8;
      }
      &._9{
         background-color: $PKS_9;
      }
      &._10{
         background-color: $PKS_10;
      }
    }

    .SSC, .SSC_FT{
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      background-color:$SSC_Yellow ;
      &.sum{
         background-color: #ffd100;
      }

      &.underLine::after{
         content: '';
         position: absolute;
         margin-top: 44px;
         width:  $ball_size;
         border: 2px solid #ffd100;
      }
    }

    .KLSF{
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         background-color:$KLSF_Red ;
         color: white;
      }
      &.yellow{
         background-color:$KLSF_Yellow;
         color: black;
      }
    }

    .SYXW{
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      background-color:$SYXW_Yellow ;
    }

    .KLCWrapper{
      flex-wrap: wrap;
      width: 480px;
    }

    .KLC{
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.darK_yellow{
         background-color:$KLC__Dark_Yellow ;
      }
      &.yellow{
         background-color:$KLC_Yellow;
      }
    }

    .FJ36X7,.FJ31X7{
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         background-color:$FJC_Red ;
         color: white;
      }
      &.yellow{
         background-color:$FJC_Yellow;
         color: black;
      }
    }

    .QG{
      color: white;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         background-color:$QG_Red ;
      }
      &.blue{
         background-color:$QG_Blue;
      }
    }

    .KS{
      width: 35px;
      height: 35px;
      margin: 6px;
    }

    .BINGOWrapper{
      width: 384px;
      flex-wrap: wrap;
    }

    .BINGO{
      &.textBox{
         border: none;
      }
      border: 3px solid;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         border-color:$HK6_Red ;
      }
      &.blue{
         border-color:$HK6_Blue;
      }
      &.green{
         border-color:$HK6_Green;
      }
      &.ZHANDLH{
         border: none;
         color: black;
         height: $ball_size;
         width: $ball_size;
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 50%;
         margin: 6px;
         background-color:$SSC_Yellow ;
      }
    }

    .BINGO_FTWrapper{
      width: 240px;
      flex-wrap: wrap;
    }

    .BINGO_FT{
      border: 3px solid;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;

      &._1{
         border-color: gray;
      }

      &._4{
         border-color:$HK6_Red ;
      }
      &._3{
         border-color:$HK6_Blue;
      }
      &._2{
         border-color:$HK6_Green;
      }

      &.textBox{
         border: none;
         color: black;
         height: $ball_size;
         width: $ball_size;
         display: flex;
         justify-content: center;
         align-items: center;
         margin: 6px;
      }
    }

    .HK6Wrapper{
      width: 432px;
      flex-wrap: wrap;
    }

    .HK6{
      &.textBox{
         border: none;
      }
      border: 3px solid;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         border-color:$HK6_Red ;
      }
      &.blue{
         border-color:$HK6_Blue;
      }
      &.green{
         border-color:$HK6_Green;
      }
    }

    table, th, td {
      border: 1px solid #B2B2B2;
      border-collapse: collapse;
    }
    th,td{
      height: 40px;
    }
    
    .homeTable{
      .blue{
         color:#5D66D3;
      }
      .red{
         color:#E86262;
      }
      .green{
         color:#4A9F5E;
      }
      width: 100%;
      text-align: center;
      th{
         @include themify($themes) {
            background-color: themed('theme_third_color');  
          }

      }
    }

    .menuItem{
      border-bottom: 1px solid transparent;
      &:hover{
         cursor: pointer;
            @include themify($themes) {
               color: themed('theme_second_color');  
               border-bottom-color: themed('theme_second_color');  
            }
         }

    }
}