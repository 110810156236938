@import '../../../scss/base.scss';

$ball_size:36px;

.ChannelHomeWrapper{
    background-color: white;
    padding: 24px 36px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #33333329;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    .ant-select-selector, .ant-picker{
      @include themify($themes) {
         border: 1px solid themed('theme_first_color');  
       }
   }

   .liangMianWrapper{
    text-align: center;
    border: 1px solid #dcdcdc;
    &>div:not(:last-child){
      border-right: 1px solid #dcdcdc;
    }
    &>div{
      &>div:first-child{
        font-weight: 700;
      }
      &>div{
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &>div:not(:last-child){
        border-bottom: 1px solid #dcdcdc;
      }
    }
    &>div:not(:first-child){
      &>div:not(:first-child){
        &>div:not(:last-child){
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          border-right: 1px solid #dcdcdc;
        }
      }
    }
   }

   .changLongItem{
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid #dcdcdc;
    padding: 10px 0px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
   }

   .cellWrapper{
    &>div:not(:last-child){
       border-right: 1px solid #C9C9C9;;
    }
   }
 
   .buttonWrapper{
    color: black;
    border-radius: 8px;
    @include themify($themes){
      border: 1px solid themed('theme_forth_color');  
    }
    padding: 5px 10px;
    margin: 5px 15px 5px 0px;
    &:hover{
      cursor: pointer;
    }
    &.selected{
      color: white;
      @include themify($themes){
        border-color: themed('theme_second_color');  
        background-color: themed('theme_second_color');  
      }
    }
  }
  .BINGOTableWrapper, 
  .PKSTableWrapper, 
  .SSCTableWrapper,
  .KSTableWrapper,
  .KLSFTableWrapper,
  .FJ36X7TableWrapper,
  .FJ31X7TableWrapper,
  .SYXWTableWrapper,
  .QGTableWrapper,
  .PKS_FTTableWrapper,
  .SSC_FTTableWrapper,
  .KLCTableWrapper{
    thead{
      tr{
        &>th:first-child{
          width: 180px;
        }
        &>th:nth-child(2){
          width: 110px;
        }
        &>th:nth-child(3){
          width: 380px;
        }
      }
    }
  }
  .BINGO_FTTableWrapper{
    thead{
      tr{
        &>th:first-child{
          width: 180px;
        }
        &>th:nth-child(2){
          width: 110px;
        }
      }
    }
  }

  .PKS,.PKS_FT{
    position: relative;
    color: white;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 6px;

    &.yellow{
      background-color: #D9A34D;
    }
    &.grey{
      background-color: #797979;
    }
    &.sum{
       background-color: #ffd100;
    }

    &.underLine::after{
       content: '';
       position: absolute;
       margin-top: 44px;
       width: $ball_size;
       border: 2px solid #ffd100;
    }

    &._1{
       background-color: $PKS_1;
    }
    &._2{
          background-color: $PKS_2;
       }
    &._3{
       background-color: $PKS_3;
    }
    &._4{
       background-color: $PKS_4;
    }
    &._5{
       background-color: $PKS_5;
    }
    &._6{
       background-color: $PKS_6;
    }
    &._7{
       background-color: $PKS_7;
    }
    &._8{
       background-color: $PKS_8;
    }
    &._9{
       background-color: $PKS_9;
    }
    &._10{
       background-color: $PKS_10;
    }
  }

  .BINGOWrapper{
    width: 384px;
    flex-wrap: wrap;
  }

  .BINGO{
    &.textBox{
       border: none;
    }
    border: 3px solid;
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;
    &.red{
       border-color:$HK6_Red ;
    }
    &.blue{
       border-color:$HK6_Blue;
    }
    &.green{
       border-color:$HK6_Green;
    }
    &.ZHANDLH{
       border: none;
       color: black;
       height: $ball_size;
       width: $ball_size;
       display: flex;
       justify-content: center;
       align-items: center;
       border-radius: 50%;
       margin: 6px;
       background-color:$SSC_Yellow ;
    }
  }

  .BINGO_FT{
    border: 3px solid;
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;

    &._1{
       border-color: gray;
    }
    &._4{
       border-color:$HK6_Red ;
    }
    &._3{
       border-color:$HK6_Blue;
    }
    &._2{
       border-color:$HK6_Green;
    }

    &.textBox{
       border: none;
       color: black;
       height: $ball_size;
       width: $ball_size;
       display: flex;
       justify-content: center;
       align-items: center;
       margin: 6px;
    }

  }

  .SSC, .SSC_FT{
    position: relative;
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;
    background-color:$SSC_Yellow ;
    &.sum{
      background-color: #ffd100;
   }

   &.underLine::after{
      content: '';
      position: absolute;
      margin-top: 44px;
      width:  $ball_size;
      border: 2px solid #ffd100;
   }

    &.yellow{
      background-color: #D9A34D;
      color: white;
    }
    &.grey{
        background-color: #797979;
        color: white;
    }
  }

  .KLSF{
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;

    &.dx_ds_yellow{
      background-color: #D9A34D;
      color: white;
    }
    &.dx_ds_grey{
        background-color: #797979;
        color: white;
    }

    &.red{
       background-color:$KLSF_Red ;
       color: white;
    }
    &.yellow{
       background-color:$KLSF_Yellow;
       color: black;
    }
  }

  .SYXW{
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;
    background-color:$SYXW_Yellow ;
    
    &.dx_ds_yellow{
      background-color: #D9A34D;
      color: white;
    }
    &.dx_ds_grey{
        background-color: #797979;
        color: white;
    }
  }

  .KLCWrapper{
    flex-wrap: wrap;
    width: 480px;
  }

  .KLC{
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;
    &.darK_yellow{
       background-color:$KLC__Dark_Yellow ;
    }
    &.yellow{
       background-color:$KLC_Yellow;
    }
  }

  .FJ36X7,.FJ31X7{
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;
    &.red{
       background-color:$FJC_Red ;
       color: white;
    }
    &.yellow{
       background-color:$FJC_Yellow;
       color: black;
    }
  }

  .QG{
    color: white;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;
    &.red{
       background-color:$QG_Red ;
    }
    &.blue{
       background-color:$QG_Blue;
    }
  }

  .KS{
    display: flex;
    justify-content: center;
    align-items: center;
    height: $ball_size;
    width: $ball_size;
    margin: 6px;
    border-radius: 5px;
    &.dx_ds_yellow{
      background-color: #D9A34D;
      color: white;
    }
    &.dx_ds_grey{
        background-color: #797979;
        color: white;
    }
  }

  .HK6Wrapper{
    width: 432px;
    flex-wrap: wrap;
  }

  .HK6{
    &.textBox{
       border: none;
    }
    border: 3px solid;
    color: black;
    height: $ball_size;
    width: $ball_size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 6px;
    &.red{
       border-color:$HK6_Red ;
    }
    &.blue{
       border-color:$HK6_Blue;
    }
    &.green{
       border-color:$HK6_Green;
    }
  }
    
}