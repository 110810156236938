@import '../../../scss/base.scss';

.homeWrapper{
      margin: 25px auto;
      color: #3B3B3B;
      .bannerWrapper{ 
        display: flex;
        width: 100%;
        height: 386px;
        max-width: 1255px;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 3px 6px #33333329;
        font-style: normal;
        font-weight: normal;
        border-radius: 22px;
        background-color: white;
        padding: 22px 20px;
        .bannerImage{
          width: 540px;
          height: 340px;
          background: transparent linear-gradient(90deg, #558EDD 0%, #545454 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 2px 10px #FFFFFF27;
          border-radius: 12px;
          opacity: 1;
        }

        .recommendLotteryWrapper{
          font-size: 13px;
          font-weight: 500;
          cursor: pointer;
          &:hover{
            @include themify($themes) {
              color: themed('theme_second_color');  
              }
          }
        }

        .bannerNews{
          &>div:nth-child(2){
            &>div{
              cursor: pointer;
              &:hover{
                @include themify($themes) {
                  color: themed('theme_second_color');  
                  }
              }
            }
          }

          .topNewsContent{
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
            white-space: pre-wrap; /* let the text wrap preserving spaces */
          }
        }
      }

      .homeCategoryRow{
        max-width: 1192px;
        margin: 20px auto;
        .homeCategoryItem{
          padding: 10px;
          border-bottom: 2px solid transparent;
          &:hover{
            cursor: pointer;
            @include themify($themes) {
              color:themed('theme_second_color');  
              }
          }
          &.selected{     
            @include themify($themes) {
              border-bottom:2px solid themed('theme_second_color');  
              color:themed('theme_second_color');  
              }
          }

        }
      }
      .homeItemWrapper{
        position: relative;
        margin: 20px auto;
        max-width: 1192px;
      }
}