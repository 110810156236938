@import '../../../scss/base.scss';

$ball_size:20px;
$ball_space:2px;

.MobileHomeDrawItem{
   .dots-1 {
      margin-left: 2px;
      margin-bottom: 5px;
      align-self: flex-end;
      width: 20px;
      height: 5px;
      background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
      clip-path: inset(0 100% 0 0);
      animation: d1 1.5s steps(4) infinite;
    }
    @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}


    &:hover{
        cursor: pointer;
    }
    background-color: white;
    padding: 10px 5px;
    font-size: 14px;
    border-radius: 12px;
    .homeGameImage{
        height: 55px;
        width: 55px;
    }
    .installmentWrapper{
        @include themify($themes) {
            color: themed('theme_second_color');  
          }
    }

    .PKS,.PKS_FT{
      position: relative;
      color: white;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: $ball_space;
      &.sum{
         background-color: #ffd100;
      }

      &.underLine::after{
         content: '';
         position: absolute;
         margin-top: 24px;
         width: $ball_size;
         border: 2px solid #ffd100;
      }
      &.underLineRed::after{
         content: '';
         position: absolute;
         margin-top: 24px;
         width: $ball_size;
         border: 2px solid #E86262;
      }
      &.underLineGreen::after{
         content: '';
         position: absolute;
         margin-top: 24px;
         width: $ball_size;
         border: 2px solid #4A9F5E;
      }


         &._1{
            background-color: $PKS_1;
         }
         &._2{
               background-color: $PKS_2;
            }
         &._3{
            background-color: $PKS_3;
         }
         &._4{
            background-color: $PKS_4;
         }
         &._5{
            background-color: $PKS_5;
         }
         &._6{
            background-color: $PKS_6;
         }
         &._7{
            background-color: $PKS_7;
         }
         &._8{
            background-color: $PKS_8;
         }
         &._9{
            background-color: $PKS_9;
         }
         &._10{
            background-color: $PKS_10;
         }
    }

    .SSC, .SSC_FT{
      position: relative;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;
      background-color:$SSC_Yellow ;
      &.sum{
         background-color: #ffd100;
      }

      &.underLine::after{
         content: '';
         position: absolute;
         margin-top: 24px;
         width:  $ball_size;
         border: 2px solid #ffd100;
      }
    }

    .KLSF{
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;
      &.red{
         background-color:$KLSF_Red ;
         color: white;
      }
      &.yellow{
         background-color:$KLSF_Yellow;
         color: black;
      }
    }

    .SYXW{
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;
      background-color:$SYXW_Yellow ;
    }

    .KLCWrapper{
      flex-wrap: wrap;
      width: 260px;
    }

    .KLC{
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;
      &.darK_yellow{
         background-color:$KLC__Dark_Yellow ;
      }
      &.yellow{
         background-color:$KLC_Yellow;
      }
    }

    .FJ36X7,.FJ31X7{
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;
      &.red{
         background-color:$FJC_Red ;
         color: white;
      }
      &.yellow{
         background-color:$FJC_Yellow;
         color: black;
      }
    }

    .QG{
      color: white;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;
      &.red{
         background-color:$QG_Red ;
      }
      &.blue{
         background-color:$QG_Blue;
      }
    }

    .KS{
      width: 27px;
      height: 27px;
      margin: $ball_space;
    }

    .BINGOWrapper{
      width: 240px;
      flex-wrap: wrap;
    }

    .BINGO{
      &.textBox{
         border: none;
         white-space: nowrap;
      }
      border: 3px solid;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 2px 5px;
      &.red{
         border-color:$HK6_Red ;
      }
      &.blue{
         border-color:$HK6_Blue;
      }
      &.green{
         border-color:$HK6_Green;
      }
      &.ZHANDLH{
         border: none;
         color: black;
         height: $ball_size;
         width: $ball_size;
         display: flex;
         justify-content: center;
         align-items: center;
         margin: 2px 5px;
         background-color:$SSC_Yellow ;
      }
    }

    .BINGO_FTWrapper{
      width: 120px;
      flex-wrap: wrap;
    }

    .BINGO_FT{
      border: 3px solid;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;

      &._1{
         border-color: gray;
      }

      &._4{
         border-color:$HK6_Red ;
      }
      &._3{
         border-color:$HK6_Blue;
      }
      &._2{
         border-color:$HK6_Green;
      }

      &.textBox{
         border: none;
         color: black;
         height: $ball_size;
         width: $ball_size;
         display: flex;
         justify-content: center;
         align-items: center;
         margin: $ball_space;
      }
    }

    .HK6Wrapper{
      width: 234px;
      flex-wrap: wrap;
    }

    .HK6{
      &.textBox{
         white-space: nowrap;
         border: none;
      }
      border: 3px solid;
      color: black;
      height: $ball_size;
      width: $ball_size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: $ball_space;
      &.red{
         border-color:$HK6_Red ;
      }
      &.blue{
         border-color:$HK6_Blue;
      }
      &.green{
         border-color:$HK6_Green;
      }
    }

    table, th, td {
      border: 1px solid #B2B2B2;
      border-collapse: collapse;
    }
    
    .homeTable{
      .underLine::after{
         content: '';
         position: absolute;
         margin-top: 24px;
         width: $ball_size;
         border: 2px solid #ffd100;
      }
      .underLineRed::after{
         content: '';
         position: absolute;
         margin-top: 24px;
         width: $ball_size;
         border: 2px solid #E86262;
      }
      .underLineGreen::after{
         content: '';
         position: absolute;
         margin-top: 24px;
         width: $ball_size;
         border: 2px solid #4A9F5E;
      }
      width: 100%;
      text-align: center;
      th{
         @include themify($themes) {
            background-color: themed('theme_third_color');  
         }

      }
   }

    .menuItem{
      border-bottom: 1px solid transparent;
      &:hover{
         cursor: pointer;
            @include themify($themes) {
               color: themed('theme_second_color');  
               border-bottom-color: themed('theme_second_color');  
            }
         }

    }
}