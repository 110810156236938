@import '../../../scss/base.scss';

.webHeaderWrapper{
  width: 100%;
     background-color: #f1f1f1;
    .toolHeader{
        max-width: 1255px;
        margin: 0 auto;
        height: 30px;
        color: #777;
        .messageWrapper{
          display: flex;
          align-items: center;
          @include themify($themes) {
            color: themed('theme_forth_color');  
            }
          position: relative;
          img{
            height: 22px;
            width: 22px;
          }
          .MarqueeWrapperStyle{
            &>.marquee{
              padding: 0px 50% !important;
            }
          }
        }
        .colorWrapper:hover>.colorPopover{
          display: grid !important;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .colorWrapper:hover{
          cursor: pointer;
        }
        .colorPopover{
          z-index: 1;
          width: 100px;
          height: 100px;
          border-radius: 10px;
          background-color: white;
          color: black;
          padding: 15px;
          .colorBlock{
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            width: 30px;
            height: 30px;
            border-radius: 5px;
              &.red{
                background-color: #E86262;
              }
              &.green{
                background-color: #4A9F5E;
              }
              &.blue{
                background-color: #5D66D3;
              }
              &.orange{
                background-color: #E38533;
              }                         
            }                  
          }
        }
        .menuHeaderNavWrapper{
          width: 100%;
          height: 50px;
          position: relative;
          @include themify($themes) {
              background-color: themed('theme_second_color');  
            }
          .menuNavHeader{
            max-width: 1255px;
            margin: 0 auto;
            height: 50px;
            @include themify($themes) {
                background-color: themed('theme_second_color');  
              }
              .logoName{
                cursor: pointer;
              }
              .menuList{
                font-size: 18px;
                &>div{
                  width: 110px;
                  height: 50px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
    
                  &.currentSelected{
                    &::after{
                      border: 1px solid white;
                    }
                  }
                                
                  &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    margin-top: 7px;
                    border: 1px solid transparent;
                  }
    
                  &:hover{
                    cursor: pointer;            
                    &::after{
                      border: 1px solid white;
                    }
                  }
    
                }
                .lotteryListWrapper{
                  z-index: 2;
                  top: 0px;
                  left: 0px;
                  display: block;
                  background-color: transparent;
                  color: black;
                  margin-top: 50px;
                  width: 100vw;
                }
              }
        }

        }
}