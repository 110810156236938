@import '../../../scss/base.scss';

$ball_size:20px;

.mobilePlanWrapper{
  width: 100%;
  padding: 10px 5px;
  font-size: 14px;
  .introduceCell{
    &>div{
       padding: 10px 0px;
    }
    &>div:first-child{
       font-weight: bold;
       border-bottom: 2px solid #B2B2B2;
    }
 }

 .tableWrapper{
    thead{
      background-color: white !important;
      }
      table, th, td {
         border-bottom: 1px solid #C9C9C9 !important;
         border-collapse: collapse;
         border-top: none !important;
         border-left: none !important;
         border-right: none !important;
       }
}

 .ant-select-selector, .ant-picker{
      @include themify($themes) {
         border: 2px solid themed('theme_first_color');  
      }
   }

   .resultSummary{
      white-space: nowrap;
      overflow-x: scroll;
   }
    .resultSummaryCell{
      word-break: keep-all;
       &>div:first-child{
          @include themify($themes) {
             color: themed('theme_second_color');  
           }
       }
       &>div{
         padding-left: 10px;
         padding-right: 10px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
       }
       &>div:not(:last-child){
          border-bottom: 1px solid #C9C9C9;
       }
    }

 .latestHeader{
    &>div:not(:last-child){
       border-right: 1px solid #C9C9C9;
    }
    &>div{
       height: 100%;
    }
    @include themify($themes) {
       background-color: themed('theme_third_color');  
     }
     &.noBgColor{
       background-color: white;
       &>div{
          color: black !important;
       }
     }
 }

 .killColorfalse{
    color: #3F80D9;
 }
 
 .killColortrue{
    @include themify($themes){
       color: themed('theme_second_color');  
     }
 }

 .killColorBgtrue{
    @include themify($themes){
       background-color: themed('theme_second_color');  
     }
 }


.PKS{
  color: white;
  height: $ball_size;
  width: $ball_size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  
     &._1{
        background-color: $PKS_1;
     }
     &._2{
           background-color: $PKS_2;
        }
     &._3{
        background-color: $PKS_3;
     }
     &._4{
        background-color: $PKS_4;
     }
     &._5{
        background-color: $PKS_5;
     }
     &._6{
        background-color: $PKS_6;
     }
     &._7{
        background-color: $PKS_7;
     }
     &._8{
        background-color: $PKS_8;
     }
     &._9{
        background-color: $PKS_9;
     }
     &._10{
        background-color: $PKS_10;
     }
}

.SSC,.KS{
  color: black;
  height: $ball_size;
  width: $ball_size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color:$SSC_Yellow ;
}

.KLSF{
  height: $ball_size;
  width: $ball_size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  
  &.red{
     background-color:$KLSF_Red ;
     color: white;
  }
  &.yellow{
     background-color:$KLSF_Yellow;
     color: black;
  }
}

.SYXW{
  color: black;
  height: $ball_size;
  width: $ball_size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  
  background-color:$SYXW_Yellow ;
}

.dots-1 {
  margin-left: 2px;
  margin-bottom: 5px;
  align-self: flex-end;
  width: 20px;
  height: 5px;
  @include themify($themes){
  background: radial-gradient(circle closest-side,white 90%,themed('theme_second_color')) 0/calc(100%/3) 100% space;
  }
  clip-path: inset(0 100% 0 0);
  animation: d1 1.5s steps(4) infinite;
}

@keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}

  @include themify($themes) {
      background-color: themed('theme_bg_color');  
    }
    width: 100%;
    .latestKillPlan{
      color: white;
      padding: 12px 5px;
      width: 100%;
      border-radius: 10px;
      @include themify($themes){
        background-color: themed('theme_second_color');  
      }
      .homeGameImage{
        height: 55px;
        width: 55px;
     } 
     .childMenu{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid white;
      width: 62px;
      height: 26px;
      border-radius: 13px;
     }
    }

    .dateAndCount{
      padding: 10px 12px;
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      @include themify($themes){
        background-color: themed('theme_second_color');  
      }
      border-radius: 5px;
    }

    .killPlanListWrapper{
      width: 100%;
      padding: 12px;
      background-color: white;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #33333329;

      .buttonWrapper{
        color: black;
        border-radius: 6px;
        @include themify($themes){
          border: 1px solid themed('theme_forth_color');  
        }
        padding: 3px 5px;
        margin: 5px 10px 5px 0px;
        &:hover{
          cursor: pointer;
        }
        &.selected{
          color: white;
          @include themify($themes){
            border-color: themed('theme_second_color');  
            background-color: themed('theme_second_color');  
          }
        }
  
      }
    }
}