@import '../../../scss/base.scss';

.mobileHomeWrapper{
    display: flex;
    flex-direction: column;
    @include themify($themes) {
      background-color: themed('theme_bg_color');  
      }
      height: 100%;
      .noticeWrapper{
        position: relative;
        height: 32px;
        display: flex;
        align-items: center;
        @include themify($themes) {
          color: themed('theme_forth_color');  
          }
        background-color: white;

        .MarqueeWrapperStyle{
          &>.marquee{
            padding: 0px 50vw !important;
          }
        }

      }
      .mobileBannerWrapper{
        position: relative;
        margin: 10px 16px;
        box-shadow: 0px 2px 10px #FFFFFF27;
        border-radius: 12px;
      }
      .menuWrapper{
        background-color: white;
        box-shadow: 0px 2px 10px #FFFFFF27;
        border-radius: 12px;
        opacity: 1;
        margin: 0px 5px 10px 5px;
        height: 75px;

        display: flex;
        justify-content: center;
        align-items: center;
        &>div{
          flex: 1;
        }
        svg{
            path{
              @include themify($themes) {
                fill: themed('theme_first_color') !important;  
              }
            }
        }
      }
      .mobileHomeCategoryRow{
        background-color: white;
        white-space: nowrap;
        overflow-x: auto;
        margin: 0px 5px;
        border-radius: 10px;
        &>div{
          padding: 10px 0px;
          border-bottom: 3px solid transparent;
          &.selected{        
            @include themify($themes) {
              color: themed('theme_second_color'); ;
              border-bottom-color: themed('theme_second_color');  
              }
          }
        }
      }
}