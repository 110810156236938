@import '../../../scss/base.scss';

.VerticalNumberTrendWrapper{
  background-color: white;
  padding: 24px 36px;
  border-radius: 12px;

  .ball{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 25px;
   height: 25px;
   border-radius: 50%;
   background-color: #FFC400;
   font-weight: 500;
  }

  .dd{
   color: white;
   background-color: #D9A34D;

  }
  .xs{
   color: white;
   background-color: #797979;
  }

  .rowCellWrapper{
   width: 100%;
   height: 100%;
   display: flex;
   &>div{
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   &>div:not(:last-child){
      border-right: 1px solid #C9C9C9;
   }
  }

  .headerCellWrapper{
   width: 100%;
   height: 100%;
   &>div{
      width: 100%;
   }
   &>div:last-child{
      border-top: 1px solid #C9C9C9;
      display: flex;
      &>div{
         flex: 1;
      }
      &>div:not(:last-child){
         border-right: 1px solid #C9C9C9;
      }
   }
  }


  .buttonWrapper{
      color: black;
      border-radius: 8px;
      @include themify($themes){
        border: 1px solid themed('theme_forth_color');  
      }
      padding: 5px 10px;
      margin: 5px 15px 5px 0px;
      &:hover{
        cursor: pointer;
      }
      &.selected{
        color: white;
        @include themify($themes){
          border-color: themed('theme_second_color');  
          background-color: themed('theme_second_color');  
        }
      }
    }
    .PKS{
      color: white;
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 6px;
         &._1{
            background-color: $PKS_1;
         }
         &._2{
               background-color: $PKS_2;
            }
         &._3{
            background-color: $PKS_3;
         }
         &._4{
            background-color: $PKS_4;
         }
         &._5{
            background-color: $PKS_5;
         }
         &._6{
            background-color: $PKS_6;
         }
         &._7{
            background-color: $PKS_7;
         }
         &._8{
            background-color: $PKS_8;
         }
         &._9{
            background-color: $PKS_9;
         }
         &._10{
            background-color: $PKS_10;
         }
    }
  
    .SSC{
      color: black;
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      background-color:$SSC_Yellow ;
    }
  
    .KLSF{
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      &.red{
         background-color:$KLSF_Red ;
         color: white;
      }
      &.yellow{
         background-color:$KLSF_Yellow;
         color: black;
      }
    }
  
    .SYXW{
      color: black;
    height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 6px;
      background-color:$SYXW_Yellow ;
    }
}