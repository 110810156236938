@import 'https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@700&display=swap';

@font-face {
    font-family: "YouSheBiaoTiHei";
    src: local("YouSheBiaoTiHei"), 
     url("../../../fonts/YouSheBiaoTiHei/YouSheBiaoTiHei.ttf") format("truetype");
}

.mobileDownloadBannerWrapper{
    padding: 0px 16px;
    height: 64px;
    background-color: #383838;
}

.bannerContent{
    font-family: adobe-heiti-std, sans-serif;
    font-style: normal;
    font-weight: 400;
}

.bannerTitle{
    font-family: "YouSheBiaoTiHei", sans-serif;
}

