@import '../../../scss/base.scss';

.changLongWrapper {
  padding: 40px 90px;
  width: 100%;
  .empty {
    padding: 10px;
  }
  .content {
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 16px;
    .loadingArea {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .commonContent {
      border-radius: 12px;
      background-color: white;
      padding: 32px;

      border: 1px solid #ffffff;
      box-shadow: 0px 3px 6px #33333329;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    .button {
      font-weight: normal;
      letter-spacing: 0px;
      &:hover {
        cursor: pointer;
      }
      &.active {
        @include themify($themes) {
          background-color: themed('theme_second_color');
          color: white;
          border: none;
        }
      }
      @include themify($themes) {
        border: 1px solid themed('theme_forth_color');
      }
      padding: 5px 10px;
      border-radius: 8px;
    }
    .filterButtonArea {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .leftContent {
      width: 264px;
      .title {
        color: #3b3b3b;
        font-weight: 900;
        font-size: 24px;
        margin-bottom: 24px;
      }
      .filterButtonArea {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 24px;
      }
      .checkArea {
        .checkTitle {
          font-size: 16px !important;
          font-weight: bold;
          @include themify($themes) {
            color: themed('theme_second_color');
          }
          margin-bottom: 15px;
        }
        .checkItemArea {
          padding-left: 15px;
        }
        .checkItemDiv {
          margin-bottom: 15px;
        }
        .checkItem {
          font-size: 16px !important;
          font-weight: normal;
        }
      }
    }
    .rightContent {
      width: 912px;
      .title {
        color: #3b3b3b;
        font-size: 32px;
        font-weight: 900;
        margin-bottom: 40px;
      }
      .actionArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        margin-bottom: 24px;
      }
      .selectArea {
        display: flex;
        align-items: center;
        gap: 50px;
        .select {
          width: 60px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .tableArea {
        table {
          width: 100%;
          .luzhuChakan {
            cursor: pointer;
            @include themify($themes) {
              color: themed('theme_first_color');
            }
          }
          th {
            padding: 10px;
            border-right: 1px solid #c9c9c9;
            @include themify($themes) {
              background-color: themed('theme_third_color');
              font-weight: medium;
            }
          }
          table-layout: fixed;
          border: 1px solid #c9c9c9;
          tr {
            text-align: center;
            font-weight: medium;
            border-top: 1px solid #c9c9c9;
            td {
              padding: 10px;
              border-left: 1px solid #c9c9c9;
            }
          }
        }
      }
    }
  }
}
