@import '../../../scss/base.scss';

.MobileAboutUsWrapper{
    width: 100%;
    max-width: 1192px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 170px;

    .menuContainer{
        padding: 0px 24px;
        position: sticky;
        top: 0px;
        width: 100%;
        display: flex;
        background-color: white;
        border-bottom: 1.5px solid #B2B2B2;
        &>div{
            color: #3B3B3B;
            padding: 10px;
            font-size: 14px;
            font-weight: 500;
            &.selected, &:hover{
                cursor: pointer;
                @include themify($themes) {
                    color: themed('theme_second_color');  
                    border-bottom: 2px solid themed('theme_second_color');
                    }
            }
        }
    }

    .contentContainer{
        margin: 10px 5px;
        line-height: 22px;
        padding: 12px;
        background-color: white;
        border: 1px solid #FFFFFF80;
        border-radius: 12px;
        .contentTitle{
            font-size: 16px;
            margin-bottom: 16px;
            font-weight: 600;

        }
        .contentSection{
            font-size: 14px;
            margin-bottom: 16px;
            .mainItem{
                display: flex;

            }
            .childItem{
                display: flex;

            }
        }
    }
}