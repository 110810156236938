@import '../../../scss/base.scss';

.mobileFooterWrapper{
    height: 55px;
    @include themify($themes) {
        background-color: themed('theme_second_color');  
      }
      .menuTabActive{
        font-weight: 600;
        @include themify($themes) {
            background-color: themed('theme_first_color') !important;  
          }
      }
      img{
        width: 25px;
        height: 25px;
      }
}